import { Accordion, AccordionSummary, AccordionDetails, Box, Chip, Paper } from '@mui/material'
import { MdExpandMore } from "react-icons/md";
import { models } from '../../../Data/models';

export const Models = () => {

  return (
    <Paper sx={{ padding: 2, mt: 2 }}>
      <div style={{ whiteSpace: "pre-line" }}>
        <h3 className="modal_header">Models</h3>
        <Box display={{ xs: 'none', md: 'block' }}>
          <table className="table-border" style={{background:"transparent", width: "auto", margin: "auto"}}>
            <thead>
              <tr><th>Model</th><th>Context Size<br />(Token window)</th><th>CUI/Sensitive Data</th><th>Air-gap / High side</th><th>Conversion</th><th>Comment</th></tr>
            </thead>
            <tbody>
              {models.map((model, index) => (
                <tr key={index}>
                  <td>
                    {model.model}
                    {model.paid && <><br />({model.paid})</>}
                  </td>
                  <td>{model.contextSize}</td>
                  <td>{model.sensitiveData}</td>
                  <td>{model.airGapHighSide}</td>
                  <td>{model.conversion}</td>
                  <td>{model.comment}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Box>
        <Box display={{ xs: 'block', md: 'none' }}>
          {models.map((model, index) => (
            <Accordion key={index} sx={{border: '1px solid #ccc', borderRadius: '5px', marginBottom: '5px'}}>
              <AccordionSummary expandIcon={<MdExpandMore />}>
                {model.model}
                {model.paid && <><br />({model.paid})</>}
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <p><strong>Context Size (Token window):</strong> {model.contextSize}</p>
                  <p><strong>CUI/Sensitive Data:</strong> {model.sensitiveData}</p>
                  <p><strong>Air-gap / High side:</strong> {model.airGapHighSide}</p>
                  <p><strong>Conversion:</strong> {model.conversion}</p>
                  <p><strong>Comment:</strong> {model.comment}</p>
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
        <br />
        1 token is about 3.7 English Characters but it varies per language and it will be drastically different for code.<br />
It is important to understand that we add various data into your prompt, including our persona rules, our embedding results (based on your datasets or our Ask Sage datasets), our plugin generated data etc.<br />
Each Large Language Model (LLM) has a different price point per token so we are issuing Ask Sage tokens that can be converted into their respective values.<br />         
You are charged for both the prompt and the completion (response).<br /><br />
        <h3 className="modal_header">Public Dataset Description</h3>
        <table className="table-border" style={{background:"transparent", width: "auto", margin: "auto"}}>
          <thead>
            <tr><th>Dataset</th><th>Description</th></tr>
          </thead>
          <tbody>
            <tr><td>Acquisition.gov</td><td>Ingest of the FAR and DFARs</td></tr>
            <tr><td>Air Force</td><td>Air Force Biographies</td></tr>
            <tr><td>Cloud One</td><td>Cloud One website</td></tr>
            <tr><td>Department of Defense</td><td>DoD policies</td></tr>
            <tr><td>DoD</td><td>DoD Biographies</td></tr>
            <tr><td>In the Nic of Time</td><td>Nic Chaillan's transcripts</td></tr>
            <tr><td>Learn with Nic</td><td>Learn with Nic content</td></tr>
            <tr><td>Nic Chaillan's Website</td><td>Nic Chaillan's Website</td></tr>
            <tr><td>Platform One</td><td>Platform One website</td></tr>
            <tr><td>Sage</td><td>Ask Sage generic training</td></tr>
          </tbody>
        </table>
      </div>
    </Paper>
  )
}