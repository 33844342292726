import { createTheme } from '@mui/material';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    chatBackground: '#282c34',
    textColorSecondary: '#e9ecef',
    dropdowns: {
      backgroundColor: '#404954',
      color: '#e9ecef',
      border: '1px solid #929396',
    },
    card: {
      backgroundColor: '#121212',
      border: '1px solid #bbbbbb',
      hover: {
        boxShadow: '0 0 0 3px #1976d2',
        backgroundColor: 'black',
        border: '1px solid #121212',
      }
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      mobile: 750,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    chatBackground: '#eeeeee',
    textColorSecondary: '#3d3e40',
    dropdowns: {
      backgroundColor: '#f0f0f0',
      color: '#3d3e40',
      border: '1px solid #3d3e40',
    },
    card: {
      backgroundColor: '#f8f8f8',
      border: '1px solid #999999',
      hover: {
        boxShadow: '0 0 0 3px #1976d2',
        backgroundColor: '#fafafa',
        border: '1px solid #f8f8f8',
      }
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      mobile: 750,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});