import { Alert, Box, Button, Typography } from '@mui/material';
import { Reference } from './Reference';

export const ChatMessageAlerts = ({ message, explainability, changeExplainability}) => {
  return (
    <>
      {message.file && (
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <img src="file.png" style={{ width: '20px' }} alt="Attachment" />
          <Typography sx={{ ml: 1 }}>{message.file.name}</Typography>
        </Box>
      )}
      {message.references && message.references.length > 0 && (
        <Alert severity='info'>
          <Typography variant="subtitle2">References:</Typography>
          {message.references.map((message_item, index) => (
            <Reference key={index} message={message_item} explainability={explainability} description={message.references_descriptions?.[index]} />
          ))}
          {message.references_descriptions !== undefined && (
            <Box sx={{ mt: 2 }}>
              <Button variant="contained" color="primary" size='small' onClick={changeExplainability}>
                {explainability ? 'Hide Explainability' : 'Show Explainability'}
              </Button>
            </Box>
          )}
        </Alert>
      )}
      {message.embedding_down === 'true' && (
        <Alert severity="error">
          <Typography color={'white'} fontSize={'inherit'}>
            Warning, embeddings are down so results do not include custom data.
          </Typography>
        </Alert>
      )}
      {message.vectors_down === 'true' && (
        <Alert severity="error">
          <Typography color={'white'} fontSize={'inherit'}>
            Warning, Vector database is down so results do not include custom data.
          </Typography>
        </Alert>
      )}
      {message.analysis_failed > 0 && (
        <Alert severity="error">
          <Typography color={'white'} fontSize={'inherit'}>
            Warning, {message.analysis_failed} reference(s) provided in this answer are not working, this might be a sign that this answer might be a GPT hallucination.
          </Typography>
        </Alert>
      )}
    </>
  )
}