import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { Label } from 'reactstrap';
import { Container, Grid, Card, CardContent, Typography, Button, Switch, FormControlLabel, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, ThemeProvider } from '@mui/material';
import * as default_data from '../config.js';
import { getUser, getToken, setWithExpiry } from '../AuthService.js';
import HeaderLogged from '../Chat/HeaderLogged'
import { lightTheme } from '../themes';

const PaymentForm = () => {
  const [plan_type, setPlanType] = useState(null);
  const [isYearly, setIsYearly] = useState(false);
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [max_tokens, setMaxTokens] = useState('UNKNOWN');
  const [max_train_tokens, setMaxTrainTokens] = useState('UNKNOWN');
  const [current_monthly_tokens, setMonthlyTokens] = useState('UNKNOWN');
  const [current_train_monthly_tokens, setTrainMonthlyTokens] = useState('UNKNOWN');

  const plans = [
    { plan_name: "200", tokens: 200000, monthlyPrice: 15, yearlyPrice: 15 * 12 },
    { plan_name: "500", tokens: 500000, monthlyPrice: 30, yearlyPrice: 30 * 11 },
    { plan_name: "1000", tokens: 1000000, monthlyPrice: 50, yearlyPrice: 50 * 11 },
    { plan_name: "2000", tokens: 2000000, monthlyPrice: 90, yearlyPrice: 90 * 11, best: true },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const loading_styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, 0.9)', // Adjust the opacity as needed
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 9999, // Adjust the z-index as needed to ensure it appears above other elements
    },
    loadingText: {
      color: '#fff',
      fontSize: '24px',
    },
  };

  const handleToggle = () => {
    setIsYearly(!isYearly);
  };

  const handlePlanSelect = (plan) => {
    var planType = isYearly ? 'yearly' : 'monthly';
    planType = (plan.tokens / 1000) + '_' + planType;

    const token = getToken();
    if (!user) {
      window.location = '/login';
      return;
    }

    setIsLoading(true);
    const domain = window.location.hostname;

    fetch(default_data.default_user_service_url_no_cac + '/checkout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },
      body: JSON.stringify({
        domain: domain,
        planType: planType,
      })
    })
    .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while sending chats.');
      })
      .then(data => {
        setIsLoading(false);  
        if(data.status != 200) {
            console.log(data);
        }
        else {          
          if(data.url != null && data.url != '') {
            window.location = data.url;
            return;
          }            
          else {
            window.location = '/update?action=success_update_checkout'
            return;
          }
        }
      })
      .catch(error => {
        setIsLoading(false);  
      });    
  };

  useEffect(() => {
    updateInfo()

    var token = getToken()

    fetch(default_data.default_chat_service_url + '/count-monthly-tokens', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-access-tokens': `${token}`
      },        
      body: JSON.stringify({
      })
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('An error occurred while sending chats.');
      })
      .then(data => {
        if(data.status != 200) {
            console.log(data.response);
        }
        else {          
          if(data.response == null || data.response == '') {
            setMonthlyTokens(0)
          }
          else {
            setMonthlyTokens(data.response)
          }
      }
      })
      .catch(error => {
  
      });    

      fetch(default_data.default_chat_service_url + '/count-monthly-teach-tokens', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-access-tokens': `${token}`
        },        
        body: JSON.stringify({
        })
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          }
          throw new Error('An error occurred while sending chats.');
        })
        .then(data => {
          if(data.status != 200) {
              console.log(data.response);
          }
          else {          
            if(data.response == null || data.response == '') {
              setTrainMonthlyTokens(0)
            }
            else {
              setTrainMonthlyTokens(data.response)
            }
          }
        })
        .catch(error => {
    
        });      


  }, []);

  const handleEnterpriseClick = () => {
    window.location.href = 'mailto:sales@asksage.ai?subject=Ask Sage Enterprise purchase';
  };

  const getCurrentPlan = () => {
    if (!plan_type) return null;
    if (!plan_type.includes('_')) return null;

    const [tokens, period] = plan_type.split('_');
    return { plan_name: tokens, tokens: parseInt(tokens) * 1000, period };
  };

  const currentPlan = getCurrentPlan();

  const handleCancelClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function updateInfo() {
    var token = getToken()

    fetch(default_data.default_user_service_url_no_cac + '/validate_token_with_full_user', {
     method: 'POST',
     headers: {
       'Content-Type': 'application/json',
       'x-access-tokens': `${token}`
     },        
     body: JSON.stringify({
     })
   })
     .then(response => {
       if (response.ok) {
         return response.json();
       }
       throw new Error('An error occurred while sending chats.');
     })
     .then(data => {
       if(data.status != 200) {
           console.log(data.response);
       }
       else {    
         setMaxTokens(data.response.max_tokens)
         setMaxTrainTokens(data.response.max_train_tokens)

         var user = data.response;
         setWithExpiry('user', JSON.stringify(user));
         setUser(user)
         
         if (user.plan_type !== 'free' && user.plan_type !== 'trial') {
             setPlanType(user.plan_type);
             setIsYearly(user.plan_type.includes('yearly'));
         }              
       }
      })
      .catch(error => {
  
      });  
  }

  return (
    <ThemeProvider theme={lightTheme}>
    <div>
    { 
      <div className='header-message'>
        { default_data.default_banner_override != null && default_data.default_banner_override != '' ?
            default_data.default_banner_override : 'UNCLASSIFIED' 
        }
      </div>            
    }
    <HeaderLogged  />

    <div className="reg-page-mode">
    <Container>
      <Row className="justify-content-center reg-page-mode-header">
        <Col xs="12" md="12" lg="12">
          <h1>Ask Sage Subscription</h1>
        </Col>
      </Row>      
      <Container style={{ padding: '10px 10px 0px 10px', backgroundColor: 'white', color: 'black' }}>
         <h3 style={{margin: '30px 20px', textAlign: 'center'}}>Tokens information</h3>
            <Label>You have {max_tokens.toLocaleString()} inference Ask Sage tokens available monthly - You have used {current_monthly_tokens.toLocaleString()} this month</Label><br />
            <Label>You have {max_train_tokens.toLocaleString()} training Ask Sage tokens available monthly - You have used {current_train_monthly_tokens.toLocaleString()} this month</Label><br />
            { user && user.plan_type !== 'unknown' ?              
                <Label>Ask Sage Subscription: {user.plan_type}</Label>
             : '' }

        { default_data.default_customer_tenant === true || user === null || user.plan_type === 'unknown' || user.plan_type === 'enterprise' ? 
        <Label style={{fontWeight: 'bold'}}>Need more tokens? We have $15 (200K tokens), $30 (500K tokens), $50 (1M tokens) and $90 (2M tokens) monthly plans reach out to us at <a href="mailto:sales@asksage.ai">sales@asksage.ai</a><br /></Label>
                    : 
        <Container style={{ padding: '10px', backgroundColor: 'white' }}>
          <h3 style={{margin: '20px 20px', textAlign: 'center'}}>Subscription</h3>
          <FormControlLabel
              control={<Switch checked={isYearly} onChange={handleToggle} />}
              label={isYearly ? 'Yearly' : 'Monthly'}
              style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}
          />
          <Grid container spacing={4} justifyContent="center">
              <Grid item xs={12} md={8}>
              <Card variant="outlined" style={{ padding: '0px' }}>
                  <Typography variant="h5" align="center" gutterBottom style={{ backgroundColor: '#3f51b5', color: 'white', padding: '10px' }}>
                  Single Users
                  </Typography>
                  <Typography variant="h7" gutterBottom style={{ padding: '10px' }}>
                  Exclusive access to Ask Sage's latest features, including:<br />
                  <ul>
                      <li>Multi modal (text, audio, image)</li>
                      <li>Access to 20+ LLMs, incl. GPT 4o, GPT 4-32K, Claude 3, LLMA3</li>
                      <li>Dozens of file ingestors and connectors</li>
                      <li>Access to plugins/agents, custom personas and prompt template library</li>
                      <li>Live+ feature, and so much more!</li>
                  </ul>
                  </Typography>
                  <Grid container spacing={4} style={{padding: '10px'}}>
                  {plans.map((plan, index) => {
                      const isCurrentPlan = currentPlan && currentPlan.plan_name === plan.plan_name && currentPlan.period === (isYearly ? 'yearly' : 'monthly');
                      return (
                      <Grid item key={index} xs={12} sm={4}>
                          <Card variant="outlined" style={{ borderWidth: '2px', borderColor: plan.best ? 'gold' : 'default', backgroundColor: isCurrentPlan ? '#e0f7fa' : 'white' }}>
                          <CardContent>
                              {isCurrentPlan && (
                              <Typography variant="body2" color="primary">
                                  Current Plan
                              </Typography>
                              )}
                              <Typography variant="h5" component="div">
                              {plan.tokens.toLocaleString()} Tokens
                              </Typography>
                              <Typography variant="h6" color="textSecondary">
                              ${isYearly ? plan.yearlyPrice : plan.monthlyPrice} / {isYearly ? 'year' : 'month'}
                              </Typography>
                              {isYearly && (
                              <Typography variant="body2" color="textSecondary">
                                  Savings: ${((plan.monthlyPrice * 12) - plan.yearlyPrice)} / year
                              </Typography>
                              )}
                              {!isCurrentPlan && (
                              <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handlePlanSelect(plan)}
                                  style={{ marginTop: '10px' }}
                              >
                                  Buy Plan Now
                              </Button>
                              )}
                          </CardContent>
                          </Card>
                      </Grid>
                      );
                  })}
                  </Grid>
              </Card>
              </Grid>
              <Grid item xs={12} md={4}>
              <Card variant="outlined" style={{ padding: '0px', backgroundColor: 'white' }}>
                  <Typography variant="h5" align="center" gutterBottom style={{ backgroundColor: '#b71c1c', color: 'white', padding: '10px' }}>
                  Enterprise
                  </Typography>
                  <Card variant="outlined" style={{margin: '10px'}}>
                  <CardContent>
                      <Typography variant="h5" component="div">
                      2,000,000 Ask Sage Tokens Bundles
                      </Typography>
                      <ul>
                      <li>Bundles of 2,000,000 Ask Sage tokens for $90 per month to spread across users (minimum of 500,000 tokens per user)</li>
                      <li>Enterprise Dashboard with user & tokens management, tracking</li>
                      </ul>
                      <Typography variant="body2" align="center" style={{ marginTop: '20px' }}>
                          OPTIONAL: Dedicated on-premise deployment (dedicated Ask Sage tenant): $120,000/year
                      </Typography>
                      <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleEnterpriseClick}
                      style={{ marginTop: '10px' }}
                      >
                      Contact Sales
                      </Button>
                  </CardContent>
                  </Card>
              </Card>
              </Grid>
          </Grid>
          {plan_type && plan_type !== 'free' && plan_type !== 'trial' && (
              <Typography variant="body2" align="center" style={{ marginTop: '20px', fontSize: 'small' }}>
              <Button onClick={handleCancelClick} style={{ fontSize: 'small', textTransform: 'none' }}>
                  Cancel my subscription
              </Button>
              </Typography>
          )}
          <Dialog open={open} onClose={handleClose}>
              <DialogTitle>Are you sure you want to cancel? Don't give up now!</DialogTitle>
              <DialogContent>
              <DialogContentText>
                  Option 1: Reach out to our team for help to see how we can help achieve your outcomes.
              </DialogContentText>
              <DialogContentText>
                  Option 2: Yes, I'm sure, cancel.
              </DialogContentText>
              </DialogContent>
              <DialogActions>
              <Button onClick={() => window.location.href = 'mailto:support@asksage.ai?subject=Ask Sage Help Required'} color="primary">
                  I need help!
              </Button>
              <Button onClick={() => window.open('https://billing.stripe.com/p/login/14k00685C9vMayI5kk', '_blank')} color="secondary">
                  Yes, I'm sure, cancel
              </Button>
              </DialogActions>
          </Dialog>
        </Container>
        }
        <Row className="justify-content-center reg-page-mode-footer">
        <Col xs="12" md="12" lg="12">
          Copyright &copy; {default_data.default_copywrite_year} Ask Sage, Inc.
        </Col>
      </Row>
    </Container>
    </Container>        
    </div>
      {/* Loading overlay */}
      {isLoading && (
        <div style={loading_styles.overlay}>
          <span style={loading_styles.loadingText}>Connecting, please wait...</span>
        </div>
      )}
</div>
</ThemeProvider>
  );
};

export default PaymentForm;
