import { MenuItem, Select, Tooltip, useTheme } from "@mui/material";

// React code to display a select menu that goes from 0.0 to 1 with 1 decimal increment and default to 0.0
export const SelectTemperature = ({value, onChange}) => {
  const theme = useTheme();
  const options = Array.from({length: 11}, (_, i) => i * 0.1).map(float => (
    <MenuItem key={float} value={float} sx={{fontSize: 13}}>
      {float === 0 || float === 1 ? float.toFixed(0) : float.toFixed(1)}
    </MenuItem>
  ));
  
  
  return (
    <Tooltip
      title="Set the models creativity" // Tooltip text
      enterDelay={2000} // Delay before tooltip appears in milliseconds
      arrow // Display an arrow on the tooltip
    >
      <Select
        value={value}
        onChange={onChange}
        defaultValue={0.0}
        style={{
          width: '65px',
          backgroundColor: theme.palette.dropdowns.backgroundColor,
        }} 
        className="select-menu"
      >
        {options}
      </Select>
    </Tooltip>
  );
};