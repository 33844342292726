import { Box, Dialog, Button, DialogTitle, IconButton, Tab, Tabs, Stack, Select, MenuItem, Typography, useTheme, Link } from '@mui/material';
import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import { Training } from './Pages/Training';
import { Capabilities } from './Pages/Capabilities';
import { Models } from './Pages/Models';
import { PaidFeatures } from './Pages/PaidFeatures';
import { ScrollableDialogContent } from '../../Components/ScrollableDialogContent';
import { Commands } from './Pages/Commands';
import { Notices } from './Pages/Notices';

export const WhatsNewDialog = ({modalIsOpen, closeModal, paid, cur_user}) => {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const tabFontSize = 17

  return (
    <Dialog
      open={modalIsOpen}
      onClose={closeModal}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {`What's New`}
          <IconButton onClick={closeModal}>
            <IoClose />
          </IconButton>
        </Box>
      </DialogTitle>
      <ScrollableDialogContent sx={{height:'80vh'}} modalIsOpen={modalIsOpen}>
        <Stack direction={{xs: 'column', sm: 'row'}}>
          <Box display={{xs: 'none', sm: 'block'}}><img className="modal_logo" src="logo.png" alt="Logo"/></Box>
          <Stack spacing={2} direction={'column'} width={'100%'}>
            <h3 className="modal_header">Welcome to Ask Sage!</h3>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%", alignContent: 'flex-end' }} display={{ xs: 'none', md: 'block'}}>
              <Tabs value={value} onChange={handleChange} aria-label="WhatsNewTabs">
                <Tab label={<Typography fontSize={tabFontSize}>Training</Typography>} />
                <Tab label={<Typography fontSize={tabFontSize}>Capabilities</Typography>} />
                <Tab label={<Typography fontSize={tabFontSize}>Models</Typography>} />
                <Tab label={<Typography fontSize={tabFontSize}>Paid Features</Typography>} />
                <Tab label={<Typography fontSize={tabFontSize}>Commands</Typography>} />
                <Tab label={<Typography fontSize={tabFontSize}>Notices</Typography>} />
              </Tabs>
            </Box>
            <Box sx={{ width: "100%" }} display={{ xs: 'block', md: 'none'}}>
              <Select 
                fullWidth 
                value={value} 
                size='small'
                onChange={(e) => {
                  setValue(e.target.value);
                }}
              >
                <MenuItem value={0}>Training</MenuItem>
                <MenuItem value={1}>Capabilities</MenuItem>
                <MenuItem value={2}>Models</MenuItem>
                <MenuItem value={3}>Paid Features</MenuItem>
                <MenuItem value={4}>Commands</MenuItem>
                <MenuItem value={5}>Notices</MenuItem>
              </Select>
            </Box>
          </Stack>
        </Stack>
        <Box>
          {value === 0 && <Training />}
          {value === 1 && <Capabilities />}
          {value === 2 && <Models />}
          {value === 3 && <PaidFeatures cur_user={cur_user} paid={paid} />}
          {value === 4 && <Commands />}
          {value === 5 && <Notices cur_user={cur_user} paid={paid} />}
        </Box>
        <br /><br />
        Latest Ask Sage Terms and Conditions: <Link sx={{textTransform: 'none', color: theme.palette.textColorSecondary}} href="/terms" rel="noopener" target="_blank">click here</Link>.<br />
        Latest Ask Sage Privacy Terms: <Link sx={{textTransform: 'none', color: theme.palette.textColorSecondary}} href="/privacy" rel="noopener" target="_blank">click here</Link>.
      </ScrollableDialogContent>
    </Dialog>
  )
}