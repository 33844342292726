import { useTheme, Box } from "@mui/material";

export const Reference = ({message, description, explainability}) => {
  const theme = useTheme()
  return (
    <Box 
      sx={{
        color: theme.palette.textColorSecondary,
        'a': {
          color: theme.palette.textColorSecondary
        }
      }
    }>
      <span className="chat-log-references-item" dangerouslySetInnerHTML={{__html: message}}>
      </span>
      { explainability === true && description !== undefined ? <span>{description.length === 0 ? '' : ' - '}<span className="chat-log-references-item" dangerouslySetInnerHTML={{__html: description}}>
      </span><br /><br /></span> : '' }
    </Box>
  );
};