import Select from 'react-select';
import { processDataset } from "../ChatBox"; 
import { useTheme } from '@mui/material';

export const SelectDataset = ({ value, datasets, onChange, noAllNone }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  
  const options = [];
  if (noAllNone !== null && noAllNone === 'true') {
  } else {
    options.push(
      { value: 'all', label: 'All' },
      { value: 'none', label: 'None' }
    );
  }

  datasets.forEach(function (obj) {
    var label = processDataset(obj);
    if (noAllNone !== null && noAllNone === 'true') {
      if (obj.indexOf('user_custom_') === -1) {
        return;
      }
    }

    options.push(
      { value: obj, label: label }
    );
  });

  const handleChange = (
    newValue,
    actionMeta
  ) => {
    switch (actionMeta.action) {
      case 'remove-value':
      case 'pop-value':
        if (actionMeta.removedValue.isFixed) {
          return;
        }
        break;
      case 'clear':
        newValue = options.filter((v) => v.isFixed);
        break;
      default:
    }
    if(newValue === undefined || newValue.length === 0) {
      if (noAllNone !== null && noAllNone === 'true') {
        newValue = []
        onChange(newValue);
        return;
      }
      else {
        newValue = [options[0]]
      }
    }
    if (newValue[newValue.length - 1].value === 'all') {
      // do something
      newValue = [newValue[newValue.length - 1]]
    }
    else if (newValue[newValue.length - 1].value === 'none') {
      // do something
      newValue = [newValue[newValue.length - 1]]
    }
    else {
      for (let i = 0; i < newValue.length; i++) {
        if (newValue[i].value === 'all' || newValue[i].value === 'none') {
          newValue.splice(i, 1)
        }
      }        
    }

    onChange(newValue);
  };

  const styles = {
    control: (styles) => ({ 
      ...styles, 
      backgroundColor: theme.palette.dropdowns.backgroundColor,
      border: theme.palette.dropdowns.border, 
      fontSize: '10pt',
      maxWidth: 500,
   }),
   valueContainer: (base) => ({
    ...base,
    maxHeight: 40,
    minWidth:100,
    overflowY: "auto",
    fontSize: '10pt',
  }),
    menuPortal: base => ({
      ...base,
      fontSize: '10pt'
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: '#eee',
      fontSize: '10pt'
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
        ? undefined
        : isSelected
        ? '#eee'
        : isFocused
        ? '#444654'
        : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected
          ? 'white'
          : isFocused
          ? 'white'
          : 'black',
          cursor: isDisabled ? 'not-allowed' : 'default',
        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled
            ? isSelected
              ? 'purple'
              : undefined
            : undefined,
        },
        fontSize: '9pt',
        padding: '3px 10px',
      };
    },
    multiValue: (styles, { data }) => {      
      return {
        ...styles,
        backgroundColor: '#24272D',
        fontSize: '10pt',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: 'white',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: 'white',
    }),
    input: (styles, { data }) => ({
      ...styles,
      color: theme.palette.text.primary,
    })
  };

  return (
    <Select
      name="dataset"
      defaultValue={[options[0]]}      
      options={options}
      value={value}
      onChange={handleChange}
      styles={styles}
      maxMenuHeight={160}      
      menuPlacement="auto"
      isMulti
      />
  );
};