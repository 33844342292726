export const plans = [
  {
    cost: 'Trial',
    interfaceTokens: '200,000',
    interfaceDetails: '(for 3mo then 1,000)',
    trainingTokens: '200,000',
    trainingDetails: '(for 3mo then 1,000)',
    features: 'None',
  }, 
  {
    cost: '$15/mo/user',
    interfaceTokens: '200,000 (8M GPT 4o-mini)',
    trainingTokens: '200,000',
    trainingDetails: 'Only $0.8 per 1M tokens',
    features: 'All',
  }, 
  {
    cost: '$30/mo/user',
    interfaceTokens: '500,000 (20M GPT 4o-mini)',
    trainingTokens: '500,000',
    trainingDetails: 'Only $0.8 per 1M tokens',
    features: 'All',
  }, 
  {
    cost: '$50/mo/user',
    interfaceTokens: '1,000,000 (40M GPT 4o-mini)',
    trainingTokens: '1,000,000',
    trainingDetails: 'Only $0.8 per 1M tokens',
    features: 'All',
  }, 
  {
    cost: '$90/mo/user',
    interfaceTokens: '2,000,000 (80M GPT 4o-mini)',
    trainingTokens: '2,000,000',
    trainingDetails: 'Only $0.8 per 1M tokens',
    features: 'All',
  },
  {
    cost: 'Enterprise Plan - $90/mo or $1,080/yr',
    interfaceTokens: '2,000,000 (80M GPT 4o-mini)*',
    trainingTokens: '2,000,000 (80M GPT 4o-mini)*',
    trainingDetails: 'Only $0.8 per 1M tokens',
    features: 'All',
  }
];