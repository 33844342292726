import {Box, IconButton, useTheme} from '@mui/material';
import {FiCopy, FiEdit, FiThumbsDown, FiTrash2} from 'react-icons/fi';
import { isBotLoggedIn } from '../../AuthService'
import { useState } from 'react';
import { unEscapeHTML } from '../../Utils/text_functions.js'


export const ChatActionButtons = ({ 
  message, 
  isLastMeMessage,
  clickHandlerDelete, 
  clickHandlerDown, 
  clickHandlerEdit,
  setChatInput
}) => {
  const theme = useTheme()
  const themeColor = theme.palette.text.primary
  const [downVote, setDownVote] = useState(message.embedding_down === 'true' ? true : false)
  const [copyState, setCopyState] = useState(false)

  const copyToClipboard = () => {
    setCopyState(true)
    navigator.clipboard.writeText(unEscapeHTML(message.message_orig));
    setTimeout(() => {
      setCopyState(false)
    }, 2000)
  }

  if (message.user === 'gpt' && message.id != null) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {!isBotLoggedIn() && !message.teach && (
          <IconButton 
            onClick={
              () => {
                setDownVote(true)
                clickHandlerDown(message.id)
              }
            } 
            aria-label="thumbs down" size='small'
          >
            <FiThumbsDown color={themeColor} style={{backgroundColor: downVote ? 'burlywood' : 'transparent'}}/>
          </IconButton>
        )}
        <IconButton onClick={copyToClipboard} aria-label="copy" size='small'>
          <FiCopy color={themeColor} style={{backgroundColor: copyState ? 'burlywood' : 'transparent'}}/>
        </IconButton>
        <IconButton onClick={() => clickHandlerDelete(message.id)} aria-label="delete" size='small'>
          <FiTrash2 color={themeColor} />
        </IconButton>
      </Box>
    );
  }

  if (message.user === 'me' && message.id != null) {
    if (isLastMeMessage) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => clickHandlerEdit(message.id, message.message, setChatInput)} aria-label="edit" size='small'>
            <FiEdit color={themeColor} />
          </IconButton>
          <IconButton onClick={() => clickHandlerDelete(message.id)} aria-label="delete" size='small'>
            <FiTrash2 color={themeColor} />
          </IconButton>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={() => clickHandlerDelete(message.id)} aria-label="delete" size='small'>
            <FiTrash2 color={themeColor} />
          </IconButton>
        </Box>
      );
    }
  }

  return null;
};
